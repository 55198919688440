<template>
  <div>
    <div class="p-4">
      <filtersList  @closeExpandAll="closeDetails"/>
    </div>
    <div class="p-4">
     <informesList ref="refList"/>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue'

export default {
  name: 'index',
  components: {
    informesList: defineAsyncComponent(() => import('../components/tableList.vue')),
    filtersList: defineAsyncComponent(() => import('../components/filters.vue'))
  },
  setup () {
    const refList = ref()
    const closeDetails = () => {
      if (refList.value) {
        refList.value.collapseAll()
      }
    }
    return {
      closeDetails,
      refList
    }
  }
}
</script>

<style scoped>

</style>
